<template>
  <svg fill="none" viewBox="0 0 284 317" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m127.977 1.528-.669-.238V42.47l.343.114 115.507 38.303-115.506 38.084-.344.114v41.404l.669-.239 154.897-55.178.333-.119V56.825l-.333-.119L127.977 1.529Z"
      fill="#E83754"
      stroke="#E83754"
      stroke-width="1.002"
    ></path>
    <path
      d="m1.147 81.364-.586-.21V141.382l.3.1 171.509 56.904L.862 254.966l-.301.099v60.556l.586-.209 228.837-81.562.292-.104v-70.717l-.292-.104L1.147 81.364Z"
      stroke="#F9C8D0"
      stroke-width="0.878"
    ></path>
  </svg>
</template>
